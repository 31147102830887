'use strict';

var docElem = document.documentElement;
var matches = docElem.matches ||
    docElem.webkitMatchesSelector ||
    docElem.mozMatchesSelector ||
    docElem.oMatchesSelector ||
    docElem.msMatchesSelector;

function traverse(el, selector, method) {
  var cur = el;
  var matcher = function (elem) {
    return selector.nodeType === 1 ? (selector === elem) : matches.call(elem, selector);
  };

  for (; cur; cur = cur[method]) {
    // using native matches, this function return true or false
    // when selector matched
    // supporting IE9+
    if (cur.nodeType === 1 && matcher(cur)) {
      return cur;
    }
  }
}

function closest(el, selector) {
  return traverse(el, selector, 'parentNode');
};

var dataLayer = window.dataLayer || [];

window.GA4 = (function () {
  var googleTagManager = {
    initPromotionClick: function (containerId) {
      var container = document.getElementById(containerId);

      if (container) {
        container.addEventListener('click', function (e) {
          var that = e.target;

          if (closest(that, 'a')) {
            googleTagManager.updateDataLayerByElem(this);
          }
        }, false);
      }
    },

    updateDataLayerByJson: function (jsonData) {
      if (typeof gtmEnabled == 'undefined') {
        return;
      }

      if (typeof dataLayer !== 'undefined' && dataLayer != null) {
        dataLayer.push({ ecommerce: null });
        dataLayer.push(jsonData);
      }
    },

    updateDataLayerByElem: function (elem) {
      if (typeof gtmEnabled == 'undefined') {
        return;
      }

      var ga4Value = elem.getAttribute('data-gtm');

      if (!ga4Value) return;

      this.updateDataLayerByJsonString(decodeURIComponent(ga4Value));
    },

    updateDataLayerByJsonString: function (jsonString) {
      if (typeof gtmEnabled == 'undefined') {
        return;
      }

      if (typeof dataLayer !== 'undefined' && dataLayer != null) {
        var jsonData = JSON.parse(jsonString);
        this.updateDataLayerByJson(jsonData);
      }
    },

    productDetail: function(gtmValue){
      var reviewCount = 0;
      var ratingValue = 0;

      setTimeout(function () {
        var jsonData = document.querySelectorAll('script[type="application/ld+json"]');
        jsonData.forEach(json => {
            var jsonld = JSON.parse(json.innerText);
            if (jsonld !== 'undefined' && jsonld.aggregateRating) {
                reviewCount = jsonld.aggregateRating.reviewCount;
                ratingValue = jsonld.aggregateRating.ratingValue;
            }
        });

        gtmValue.ecommerce.detail.products[0].reviewCount = reviewCount ? reviewCount : 0;
        gtmValue.ecommerce.detail.products[0].reviewRating = ratingValue ? ratingValue : 0;

        var jsonString = JSON.stringify(gtmValue)
        GA4.updateDataLayerByJsonString(jsonString);

      }, 3000);
    },

    productClick: function (elem) {
      this.setAttributes(elem);
      this.updateDataLayerByElem(elem);
    },

    productCompareBox: function (elem) {
      var productTile = elem.closest('.product-tile');
      var ga4Value = productTile.getAttribute('data-gtm');
      if (!ga4Value) return;

      ga4Value = decodeURIComponent(ga4Value);
      var jsonData = JSON.parse(ga4Value);
      jsonData.event = 'compare_box';
      this.updateDataLayerByJson(jsonData);
    },

    selectItem: function (elem) {
      this.setAttributes(elem);
      var ga4Value = elem.getAttribute('data-gtm');
      ga4Value = decodeURIComponent(ga4Value);
      var jsonData = JSON.parse(ga4Value);
      jsonData.event = 'select_item';
      this.updateDataLayerByJson(jsonData);
    },

    addToCartClick: function (elem) {
      if (typeof productDetailData !== 'undefined' && productDetailData != null) {
        var ga4Value = JSON.parse(decodeURIComponent(elem.getAttribute('data-gtm')));
        ga4Value.ecommerce.items[0].pickupStock = productDetailData.ecommerce.detail.products[0].pickupStock;
        ga4Value.ecommerce.items[0].deliveryStock = productDetailData.ecommerce.detail.products[0].deliveryStock;
        ga4Value.ecommerce.items[0].sale = productDetailData.ecommerce.detail.products[0].sale;
        ga4Value.ecommerce.items[0].productType = productDetailData.ecommerce.detail.products[0].productType;
        var reviewCount = 0;
        var ratingValue = 0;
        var _this = this;
        setTimeout(function () {
            var jsonData = document.querySelectorAll('script[type="application/ld+json"]');
            jsonData.forEach(json => {
                var jsonld = JSON.parse(json.innerText);
                if (jsonld !== 'undefined' && jsonld.aggregateRating) {
                    reviewCount = jsonld.aggregateRating.reviewCount;
                    ratingValue = jsonld.aggregateRating.ratingValue;
                }
            });
    
            ga4Value.ecommerce.items[0].reviewCount = reviewCount ? reviewCount : 0;
            ga4Value.ecommerce.items[0].reviewRating = ratingValue ? ratingValue : 0;

            elem.setAttribute('data-gtm', encodeURIComponent(JSON.stringify(ga4Value)));
    
            _this.setQuantityAttr(elem);
            _this.updateDataLayerByElem(elem);
    
        }, 1000);
      } else {
        this.setQuantityAttr(elem);
        this.updateDataLayerByElem(elem);
      }
    },

    addToWishlist: function (elem, data) {
      if (!data.error) {
        this.updateDataLayerByElem(elem);
      }
    },

    removeFromCartClick: function (elem) {
      this.setQuantityAttr(elem);
      this.updateDataLayerByElem(elem);
    },

    addToWishListClick: function (elem) {
      this.updateDataLayerByElem(elem);
    },

    removeFromWishListClick: function (elem) {
      this.updateDataLayerByElem(elem);
    },

    checkoutBeginClick: function (elem) {
      this.updateDataLayerByElem(elem);
    },

    checkoutStepsClick: function (elem, isPayment) {
      if (isPayment != 'undefined' && isPayment) {
        this.setOption(elem);
      }
      this.updateDataLayerByElem(elem);
    },

    updateCheckoutAction: function (elem, step, isPayment) {
      var ga4Value = JSON.parse(decodeURIComponent(elem.getAttribute('data-gtm')));

      ga4Value.ecommerce.checkout.actionField.step = step;

      if (isPayment) {
        this.setOption(elem);
      } else {
        elem.removeAttribute('data-payment');
        ga4Value.ecommerce.checkout.actionField.option = '';
      }

      elem.setAttribute('data-gtm', encodeURIComponent(JSON.stringify(ga4Value)));
    },

    setOption: function (elem) {
      var selectedPaymentOption = document.getElementById('selectedPaymentOption');

      if (!selectedPaymentOption) return;

      var paymentMethod = selectedPaymentOption.value;
      var ga4Value = JSON.parse(decodeURIComponent(elem.getAttribute('data-gtm')));

      ga4Value.ecommerce.checkout.actionField.option = paymentMethod;

      elem.setAttribute('data-gtm', encodeURIComponent(JSON.stringify(ga4Value)));
    },

    setQuantityAttr: function (elem) {
      var quantity = +elem.getAttribute('data-quantity');

      var qtyControl;
      var container = elem.closest('body');
      if(container.querySelector('.wishlistItemCards')){
        container = elem.closest('.product-info-content');
      }

      qtyControl = container.querySelector('.quantity-select') ? container.querySelector('.quantity-select') : container.querySelector('select[name$=quantity]');

      if ($("#plpQuickViewModal").css('display') === "none") {
        quantity = 1;
      } else if(elem.closest('.swiper-carousel')) {
        quantity = 1;
      } else {
        quantity = quantity || (qtyControl && qtyControl.value) || 1;
      }

      var ga4Value = JSON.parse(decodeURIComponent(elem.getAttribute('data-gtm')));

      ga4Value.ecommerce.items[0].quantity = quantity;

      elem.setAttribute('data-gtm', encodeURIComponent(JSON.stringify(ga4Value)));
    },

    setAttributes: function (elem) {
      var gtmValue = JSON.parse(decodeURIComponent(elem.getAttribute('data-gtm')));

      var container = elem.closest('body');
      var itemId = container.querySelector('.color-swatches') && container.querySelector('.card-body').getAttribute('data-variation-id');
      var itemVariant = container.querySelector('.color-swatches') && container.querySelector('.card-body').getAttribute('data-product-variant');

      if (itemId) {
        gtmValue.ecommerce.items[0].item_id = itemId;
      }

      if (itemVariant) {
        gtmValue.ecommerce.items[0].item_variant = itemVariant;
      }

      var listName = $('#item_list_name').val();
      if (listName) {
        gtmValue.ecommerce.items[0].item_list_name = listName;
      }

      elem.setAttribute('data-gtm', encodeURIComponent(JSON.stringify(gtmValue)));
    },
  };
  return googleTagManager;
}());
